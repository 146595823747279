@use '../abstract' as *;
.banner-slider {
    overflow: hidden;
}

.creating-value-slide {
    // background: url('/images/creating-value-bg.svg');
    // background-repeat: no-repeat;
    // background-size: 58%;
    // background-position: right 120%;
}

.banner {
    display: flex;
    width: 100%;
    min-height: calc(70vh - 32px);
    align-items: center;
    background-image: url('/images/banner-background.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center bottom;
    padding-top: 72px;
}

.banner-inner {
    width: 90%;
    margin: 0 auto;
    max-width: $content-width-max;
    &.banner-home {
        display: flex;
        .banner-left {
            width: 55%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .section-background {
            width: 45%;
            img {
                width: 100%;
            }
            object {
                width: 75%;
            }
        }
    }
}

.section-inner,
.footer-inner {
    width: 90%;
    max-width: $content-width-max;
    margin: 0 auto;
}

.section-inner {
    padding-top: 120px;
}

.section-heading-bold {
    @include font(40px,
    600);
    text-align: center;
    text-transform: capitalize;
}

.section-p {
    width: 90%;
    text-align: center;
    @include font(14px,
    400);
    line-height: 26px;
    margin: 0 auto 72px;
    color: lighten($color: $mid-gray, $amount: 15%);
}

.heading-seperator {
    width: 100%;
    height: 8px;
    display: flex;
    justify-content: center;
    margin: 8px 0 30px;
    .bar {
        width: 160px;
        height: 8px;
        background-color: $light;
        position: relative;
        &::after {
            content: '';
            display: flex;
            position: absolute;
            width: 60%;
            height: 8px;
            background-color: $primary;
            top: 0;
            left: 0;
        }
    }
}

h1.banner-header {
    @include font($bannerHeader,
    800);
    margin-bottom: 48px;
    span {
        display: inline-flex;
        @include font($bannerHeader,
        800);
        background: $gradientRedtoPinkAngled;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

.banner-p {
    @include font($bannerPara,
    400);
    width: 60%;
    //margin-bottom: 120px;
}

.dashes {
    height: 12px;
    width: auto;
    display: flex;
    margin-top: 120px;
    .dash {
        width: $dashWidth;
        background-color: $black;
        &.red {
            background-color: $primary;
        }
        &.blue {
            background-color: #3583aa;
        }
        &.black {
            background-color: $dark-gray;
        }
        &.grey {
            background-color: $mid-gray;
        }
        &.light {
            background-color: $light;
        }
    }
}

.banner-breaker {
    position: relative;
    padding: 108px 0;
    background: $bannerBreakerBG;
    &:before {
        position: absolute;
        content: '';
        width: 40%;
        height: 80%;
        left: 0;
        top: 0;
        z-index: 1;
        background: url('/images/banner-breaker-bg-white.png') no-repeat left top;
    }
    &:after {
        position: absolute;
        content: '';
        width: 100%;
        height: 40%;
        right: 0;
        bottom: 0;
        z-index: 1;
        background: url('/images/banner-breaker-bg.svg') no-repeat right top;
    }
    .banner-breaker-inner {
        position: relative;
        z-index: 10;
        width: 90%;
        max-width: $content-width-max;
        margin: 0 auto;
        text-align: center;
    }
    .banner-sub-heading {
        @include font($bannerBreakerHeading,
        700);
        color: $primary;
        margin-bottom: 24px;
    }
    p {
        @include font(16px,
        500);
        color: $dark-gray;
        width: 50%;
        margin: 0 auto;
    }
}

.home-card-wrap {
    width: 90%;
    max-width: $content-width-max;
    margin: 0 auto;
    padding: 80px 0px;
    .home-card {
        width: 100%;
        display: flex;
        border-radius: 16px;
        background-color: rgba($color: #EAEAEA, $alpha: .17);
        min-height: $home-card-height;
        margin-bottom: 80px;
        &:nth-child(even) {
            .card-point {
                order: 2;
            }
            .card-content {
                order: 1;
                text-align: right;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                img {
                    right: unset;
                    left: 20px;
                }
            }
        }
        &:first-child {
            .card-point {
                background: $gradientRedtoPink;
            }
        }
        &:nth-child(2) {
            .card-point {
                background: $gradientOrangetoYellow;
            }
        }
        &:nth-child(3) {
            .card-point {
                background: $gradientBluetoPurple;
            }
        }
        &:nth-child(4) {
            .card-point {
                background: $gradientGreentoYellow;
            }
        }
    }
    .card-point {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 15%;
        background-color: $black;
        flex-direction: column;
        padding: 16px;
        border-radius: 16px;
        min-height: $home-card-height;
        span {
            display: flex;
            color: $white;
            text-transform: capitalize;
        }
        .number {
            @include font(60px,
            700);
            color: $white;
        }
        .statement {
            @include font(20px,
            600);
            color: $white;
        }
    }
    .card-content {
        width: 85%;
        padding: 24px 32px;
        position: relative;
        .card-header {
            @include font(32px,
            400);
            margin-bottom: 24px;
        }
        .card-content-p {
            @include font(16px,
            400);
            width: 85%;
            line-height: 26px;
            color: $mid-gray;
        }
        img {
            width: 60px;
            height: 60px;
            position: absolute;
            right: 20px;
            bottom: 20px;
        }
    }
}

.teams {
    padding: 10px 0 0 0;
    background: $gradientTeamsBG;
    .teams-inner {
        text-align: center;
        padding-top: 0;
    }
    img {
        width: 80%;
        margin: 96px auto 0;
    }
}

.sectors-inner,
.services-inner {
    .section-p {
        width: 80%;
        text-align: center;
    }
}

.sectors-inner {
    padding-top: 150px;
}

.sectors-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .sector-list-item {
        display: flex;
        flex-direction: column;
        width: calc((100% / 8) - 12px);
        justify-content: center;
        align-items: center;
        background-color: #3583aa;
        height: 172px;
        border-radius: 16px;
        margin-top: 32px;
        .sector-name {
            color: $white !important;
            @include font(20px,
            300);
        }
        &:nth-child(1) {
            background: $gradient-one;
        }
        &:nth-child(2) {
            background: $gradient-two;
        }
        &:nth-child(3) {
            background: $gradient-three;
        }
        &:nth-child(4) {
            background: $gradient-four;
        }
        &:nth-child(5) {
            background: $gradient-five;
        }
        &:nth-child(6) {
            background: $gradient-six;
        }
        &:nth-child(7) {
            background: $gradient-seven;
        }
        &:nth-child(8) {
            background: $gradient-eight;
        }
    }
    .sector-image {
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 18px;
        img {
            width: 100%;
        }
    }
    &.service-page-sector-list {
        margin-bottom: 64px;
    }
}

.implimetations-inner {
    padding-top: 150px;
    img {
        width: 100%;
        padding-top: 32px;
    }
    object {
        width: 100%;
        padding: 10px;
    }
}

.services-inner {
    .services-cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .s-card {
            display: flex;
            flex-direction: column;
            width: calc((100% / 4) - 16px);
            padding: 32px 22px 75px;
            align-items: center;
            min-height: 390px;
            box-shadow: 0 -53px 45px rgb(168 168 168 / 17%);
            margin: 16px 0 12px;
            justify-content: flex-start;
            .s-card-head {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-direction: column;
                margin-bottom: 20px;
            }
            img {
                width: 100px;
                height: 100px;
            }
        }
        .s-card-header {
            @include font(18px,
            500);
            text-transform: capitalize;
            text-align: center;
            margin-bottom: 5px;
            min-height: 55px;
        }
        .s-card-info {
            @include font(14px,
            300);
            text-align: center;
            color: $mid-gray;
            line-height: 1.5;
        }
    }
}

.experts-heading {
    text-align: center;
    text-transform: uppercase;
    margin: 100px 0 80px;
}

.experts {
    .expertice-line-wrapper {
        width: 100%;
        height: 100px;
        display: block;
        margin-bottom: 64px;
        overflow: hidden;
        .expertise-line {
            .expertice {
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    max-height: 100%;
                }
            }
        }
    }
}

footer {
    padding: 50px 0;
    background: rgba($color: $light, $alpha: .35);
    // margin-top: 48px;
}

.footer-inner {
    display: flex;
    justify-content: space-between;
}

.footer-links {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    margin-right: 100px;
    border-right: 1px solid rgba($color: #707070, $alpha: .6);
    ul {
        width: 30%;
        li {
            margin-bottom: 16px;
            a {
                @include base-transition(color);
                @include font(14px,
                500);
            }
            &:hover {
                a {
                    color: $primary;
                }
            }
        }
    }
    .list-headear {
        @include font(18px,
        600);
        margin-bottom: 24px;
    }
}

.contact-links {
    .lets-talk {
        background: $primary;
        color: $white;
        border-radius: 8px;
        padding: 16px 24px;
        display: flex;
        @include font(16px,
        600,
        $white);
        margin-bottom: 32px;
    }
    ul {
        li {
            margin-bottom: 16px;
            @include font(16px,
            400);
        }
    }
    .social-media {
        margin: 32px 0;
        display: flex;
        li {
            margin-right: 12px;
        }
    }
}

.footer-end-inner {
    padding: 34px 0;
    display: flex;
    justify-content: space-between;
    .copyrights {
        display: flex;
        flex-grow: 2;
        align-items: center;
        img {
            margin-right: 22px;
        }
    }
    .footer-end-links {
        display: flex;
        a {
            color: $dark-gray;
            display: flex;
            margin-left: 16px;
            @include font(14px,
            400);
            &:hover {
                color: $primary;
            }
        }
    }
}

.experts-mobile {
    display: none;
}

.scit-tags {
    display: flex;
    margin-top: 0px;
    span {
        display: flex;
        height: 36px;
        min-width: 100px;
        margin-right: 5px;
        border-radius: 10px;
        justify-content: center;
        align-items: center;
        padding: 0 15px;
        @include font(14px,
        800,
        $mid-gray);
        text-transform: uppercase;
        position: relative;
        padding-left: 24px;
        letter-spacing: 1px;
        &:before {
            content: '';
            height: 9px;
            width: 9px;
            border: 4px solid $primary;
            border-radius: 50%;
            position: absolute;
            left: 0;
        }
    }
}

@media screen and (max-width: 1400px) {
    .creating-value-slide {
        background-size: 58%;
        background-position: right 120%;
    }
    .banner {
        height: calc(100vh - 32px);
        align-items: center;
        background-size: 100%;
        padding-top: 72px;
    }
    .banner-inner {
        width: 80%;
        &.banner-home {
            display: flex;
            .banner-left {
                width: 55%;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            .section-background {
                width: 45%;
            }
        }
    }
    .section-inner,
    .footer-inner {
        width: 80%;
    }
    .section-inner {
        padding-top: 72px;
    }
    .section-heading-bold {
        @include font(24px,
        600);
        text-align: center;
        text-transform: capitalize;
    }
    .section-p {
        width: 60%;
        text-align: center;
        @include font(12px,
        400);
        line-height: 20px;
        margin: 0 auto 72px;
        color: lighten($color: $mid-gray, $amount: 15%);
    }
    .heading-seperator {
        height: 6px;
        .bar {
            height: 6px;
            &::after {
                height: 6px;
            }
        }
    }
    h1.banner-header {
        @include font($bannerHeaderTab,
        800);
        span {
            @include font($bannerHeaderTab,
            800);
        }
    }
    .banner-p {
        @include font($bannerParaTab,
        400);
        width: 60%;
    }
    .dashes {
        height: 8px;
        margin-top: 72px;
        .dash {
            width: calc($dashWidth * .75);
        }
    }
    .banner-breaker {
        padding: 64px 0;
        background: $bannerBreakerBG, url('/images/banner-breaker-bg.svg') no-repeat right bottom, url('/images/banner-breaker-bg-white.png') no-repeat left top;
        ;
        position: relative;
        .banner-sub-heading {
            @include font($bannerBreakerHeadingTab,
            700,
            $primary);
            margin-bottom: 24px;
        }
        p {
            @include font(14px,
            500);
            line-height: 24px;
            width: 50%;
        }
    }
    .home-card-wrap {
        width: 80%;
        padding: 80px 0px 24px;
        .home-card {
            border-radius: 16px;
            min-height: $home-card-height;
            margin-bottom: 60px;
        }
        .card-point {
            width: 20%;
            padding: 16px;
            span {
                display: flex;
                color: $white;
                text-transform: capitalize;
            }
            .number {
                @include font(48px,
                700);
                color: $white;
            }
            .statement {
                @include font(18px,
                600);
                color: $white;
            }
        }
        .card-content {
            width: 85%;
            padding: 24px 32px;
            position: relative;
            .card-header {
                @include font(20px,
                400);
                margin-bottom: 24px;
                width: 90%;
            }
            .card-content-p {
                @include font(12px,
                500);
                width: 90%;
                line-height: 22px;
                color: $mid-gray;
            }
            img {
                width: 60px;
                height: 60px;
                position: absolute;
                right: 20px;
                bottom: 20px;
            }
        }
    }
    .teams {
        padding: 10px 0 0 0;
        background: $gradientTeamsBG;
        .teams-inner {
            text-align: center;
            padding-top: 0;
        }
        img {
            width: 80%;
            margin: 96px auto 0;
        }
    }
    .sectors-inner,
    .services-inner {
        .section-p {
            width: 80%;
            text-align: center;
        }
    }
    .sectors-inner {
        padding-top: 96px;
    }
    .sectors-list {
        width: 100%;
        .sector-list-item {
            width: calc((100% / 8) - 12px);
            height: 144px;
            border-radius: 8px;
            margin-top: 0;
            .sector-name {
                color: $white !important;
                @include font(16px,
                300);
            }
        }
        .sector-image {
            width: 36px;
            height: 36px;
        }
        &.service-page-sector-list {
            margin-bottom: 64px;
        }
    }
    .implimetations-inner {
        padding-top: 96px;
        img {
            width: 100%;
            padding-top: 32px;
        }
        object {
            padding: 0 10%;
        }
    }
    .services-inner {
        .services-cards {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .s-card {
                padding: 32px 22px 54px;
                min-height: 360px;
                margin: 16px 0 12px;
                justify-content: flex-start;
                .s-card-head {
                    margin-bottom: 20px;
                }
                img {
                    width: 80px;
                    height: 80px;
                }
            }
            .s-card-header {
                @include font(16px,
                500);
            }
            .s-card-info {
                @include font(12px,
                300);
            }
        }
    }
    .experts-heading {
        margin: 48px 0 60px;
    }
    .experts {
        .expertice-line-wrapper {
            margin-bottom: 40px;
            overflow: hidden;
            .expertise-line {
                .expertice {
                    height: 50px;
                    padding: 0 20px;
                    img {
                        max-width: 80%;
                        max-height: 100%;
                    }
                }
            }
        }
    }
    footer {
        padding: 46px 0;
        background: rgba($color: $light, $alpha: .35);
        // margin-top: 48px;
    }
    .footer-inner {}
    .footer-links {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        margin-right: 100px;
        border-right: 1px solid rgba($color: #707070, $alpha: .6);
        ul {
            width: 30%;
            li {
                margin-bottom: 16px;
                a {
                    @include base-transition(color);
                    @include font(10px,
                    500);
                }
                &:hover {
                    a {
                        color: $primary;
                    }
                }
            }
        }
        .list-headear {
            @include font(14px,
            600);
            margin-bottom: 24px;
        }
    }
    .contact-links {
        .lets-talk {
            background: $primary;
            color: $white;
            border-radius: 8px;
            padding: 16px 24px;
            display: flex;
            @include font(14px,
            600,
            $white);
            margin-bottom: 32px;
        }
        ul {
            li {
                margin-bottom: 16px;
                @include font(12px,
                400);
            }
        }
        .social-media {
            margin: 32px 0;
            display: flex;
            li {
                margin-right: 12px;
            }
        }
    }
    .footer-end-inner {
        padding: 32px 0 !important;
        display: flex;
        justify-content: space-between;
        .copyrights {
            display: flex;
            flex-grow: 2;
            align-items: center;
            img {
                margin-right: 24px;
            }
            span {
                font-size: 12px;
            }
        }
        .footer-end-links {
            display: flex;
            a {
                color: $dark-gray;
                display: flex;
                margin-left: 16px;
                @include font(12px,
                400);
                &:hover {
                    color: $primary;
                }
            }
        }
    }
    .experts-mobile {
        display: none;
    }
}

@media screen and (max-width: 1365px) {
    .scit-tags {
        span {
            height: 36px;
            min-width: auto;
            padding: 0 10px 0 18px;
            @include font(11px,
            800,
            $mid-gray);
            &:before {
                width: 7px;
                height: 7px;
                border-width: 3px;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .banner {
        background-size: 200%;
        background-position: right bottom;
    }
    .banner-inner {
        width: 100%;
        padding: 100px 16px 0;
        &.banner-home {
            display: flex;
            position: relative;
            .banner-left {
                width: 100%;
            }
            .section-background {
                width: 90%;
                position: absolute;
                bottom: -20%;
                right: 8%;
                opacity: 0.15;
            }
        }
    }
    .section-inner,
    .footer-inner {
        width: 100%;
        padding: 0 16px;
    }
    .section-inner {
        padding-top: 64px;
    }
    .section-heading-bold {
        @include font(48px,
        700);
    }
    .section-p {
        width: 100%;
        padding: 0 16px;
    }
    h1.banner-header {
        @include font($bannerHeaderTab,
        800);
        margin-bottom: 60px;
        text-align: center;
        span {
            display: flex;
            @include font($bannerHeaderTab,
            800);
            background: $gradientRedtoPink;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            text-align: center;
            justify-content: center;
        }
    }
    .banner-p {
        @include font($bannerPara,
        600);
        width: 60%;
        margin: 0 auto 120px;
        text-align: center;
    }
    .dashes {
        justify-content: center;
    }
    .banner-breaker {
        padding: 180px 0;
        background: $bannerBreakerBG;
        &:before {
            width: 100%;
            height: 50%;
        }
        &:after {
            width: 100%;
            height: 30%;
        }
    }
    .home-card-wrap {
        width: 100%;
        max-width: $content-width-max;
        margin: 0 auto;
        padding: 80px 16px 0;
        .card-point {
            width: 30%;
        }
        .card-content {
            width: 70%;
        }
    }
    .teams {
        padding: 0px 0 0 0;
    }
    .sectors-inner,
    .services-inner {
        .section-p {
            width: 100%;
            padding: 0 16px;
            text-align: center;
        }
    }
    .sectors-list {
        flex-wrap: wrap;
        .sector-list-item {
            width: calc(25% - 1px);
            border-radius: 0px;
            margin-top: 1px;
        }
    }
    .services-inner {
        .services-cards {
            .s-card {
                width: calc((100% / 2) - 16px);
                padding: 32px 32px;
                min-height: unset;
                box-shadow: 0 -53px 45px rgb(168 168 168 / 17%);
                margin: 16px 0 16px;
            }
        }
    }
    .experts {
        display: none;
    }
    .experts-heading {
        text-align: center;
        text-transform: uppercase;
        margin: 40px 0 40px;
    }
    .experts-mobile {
        display: block;
        padding: 0 0 48px;
        .expertice-line-wrapper-mobile {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            align-items: center;
            .expertise-line {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 16px;
                min-height: 92px;
            }
        }
    }
    footer {
        padding: 48px 0;
        background: rgba($color: $light, $alpha: .35);
    }
    .footer-inner {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .footer-links {
        display: flex;
        flex-wrap: wrap;
        flex-grow: 1;
        justify-content: space-between;
        margin-right: 0px;
        border-right: none;
        width: 100%;
        ul {
            width: auto;
            // &:nth-child(2),
            // &:nth-child(3) {
            //     width: 50%;
            // }
            li {
                margin-bottom: 16px;
                a {
                    @include base-transition(color);
                }
                &:hover {
                    a {
                        color: $primary;
                    }
                }
            }
        }
        .list-headear {
            @include font(20px,
            600);
            margin-bottom: 24px;
        }
    }
    .contact-links {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .lets-talk {
            background: $primary;
            color: $white;
            border-radius: 8px;
            padding: 16px 24px;
            display: flex;
            @include font(20px,
            600,
            $white);
            margin-bottom: 32px;
        }
        ul {
            li {
                margin-bottom: 16px;
                @include font(16px,
                400);
                text-align: center;
            }
        }
        .social-media {
            margin: 32px 0;
            display: flex;
            li {
                margin: 12px;
            }
        }
    }
    .footer-end-inner {
        padding: 60px 0;
        display: flex;
        justify-content: space-between;
        .copyrights {
            display: flex;
            flex-grow: 2;
            align-items: center;
            img {
                margin-right: 24px;
            }
        }
        .footer-end-links {
            display: flex;
            a {
                color: $dark-gray;
                display: flex;
                margin-left: 16px;
                &:hover {
                    color: $primary;
                }
            }
        }
    }
    .footer-end-inner {
        padding: 48px 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .copyrights {
            display: flex;
            flex-direction: column;
            flex-grow: 2;
            align-items: center;
            justify-content: center;
            span {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
            }
            img {
                margin: 24px;
            }
        }
        .footer-end-links {
            display: flex;
            justify-content: space-around;
            margin: 32px 0;
            a {
                color: $dark-gray;
                display: flex;
                margin-left: 16px;
                &:hover {
                    color: $primary;
                }
            }
        }
    }
    .scit-tags {
        align-self: center;
    }
}

@media screen and (max-width: 767px) {
    .inner-page-banner {
        &.company-info-pages {
            background-size: cover !important;
        }
    }
    .banner {
        height: calc(100vh - 16px);
        background-size: 450%;
        background-position: right bottom;
    }
    .banner-inner {
        width: 100%;
        padding: 100px 16px 0;
        .section-background {
            display: none;
        }
    }
    .section-inner,
    .footer-inner {
        width: 100%;
        padding: 0 16px;
        &.careers-inner {
            padding: 0;
        }
    }
    .section-inner {
        padding-top: 60px;
    }
    .section-heading-bold {
        @include font(36px,
        700);
        text-align: center;
        text-transform: capitalize;
    }
    .section-p {
        width: 90%;
        text-align: center;
        @include font(16px,
        400);
        line-height: 26px;
        margin: 0 auto 24px;
    }
    .heading-seperator {
        height: 8px;
        margin: 24px 0 36px;
        .bar {
            width: 150px;
            height: 8px;
            &::after {
                width: 60%;
                height: 8px;
            }
        }
    }
    h1.banner-header {
        @include font($bannerHeaderMobile,
        800);
        margin-bottom: 48px;
        text-align: center;
        span {
            display: flex;
            @include font($gradientRedtoPinkAngled,
            800);
            background: $gradientRedtoPinkAngled;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
    .banner-p {
        @include font($bannerParaMobile,
        600);
        width: 90%;
        text-align: center;
        margin-bottom: 120px;
    }
    .dashes {
        height: 8px;
        justify-content: center;
        .dash {
            width: $dashWidthMobile;
        }
    }
    .banner-breaker {
        padding: 180px 0;
        background: $bannerBreakerBG;
        .banner-breaker-inner {
            width: 100%;
            padding: 0 16px;
        }
        .banner-sub-heading {
            @include font($bannerBreakerHeadingMobile,
            700,
            $primary);
        }
        p {
            @include font(16px,
            500);
            color: $dark-gray;
            width: 95%;
        }
    }
    .home-card-wrap {
        width: 100%;
        padding: 80px 0px 0;
        .home-card {
            width: 100%;
            border-radius: 0px;
            background-color: rgba($color: #EAEAEA, $alpha: .17);
            min-height: unset;
            margin-bottom: 0px;
            padding-bottom: 64px;
            flex-direction: column;
            &:nth-child(even) {
                .card-point {
                    order: 1;
                }
                .card-content {
                    order: 2;
                    text-align: left;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    img {
                        right: 20px;
                        left: unset;
                    }
                }
            }
            &:nth-child(2) {
                .card-point {
                    &:after {
                        background: url('/images/people-white.png');
                        background-size: contain;
                        background-repeat: no-repeat;
                    }
                }
            }
            &:nth-child(3) {
                .card-point {
                    &:after {
                        background: url('/images/chat-white.png');
                        background-size: contain;
                        background-repeat: no-repeat;
                    }
                }
            }
            &:nth-child(4) {
                .card-point {
                    &:after {
                        background: url('/images/coronavirus-white.png');
                        background-size: contain;
                        background-repeat: no-repeat;
                    }
                }
            }
        }
        .card-point {
            flex-wrap: wrap;
            width: 100%;
            background-color: $black;
            flex-direction: column;
            padding: 32px 16px;
            border-radius: 0px;
            min-height: auto;
            position: relative;
            &:after {
                content: '';
                width: 60px;
                height: 60px;
                background: url('/images/milestone-white.png');
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                top: 32px;
                right: 20px;
            }
            .number {
                margin-bottom: 24px;
            }
            .statement {
                width: 60%;
            }
        }
        .card-content {
            width: 100%;
            padding: 24px 16px;
            position: relative;
            .card-header {
                @include font(24px,
                500);
                margin-bottom: 24px;
            }
            .card-content-p {
                @include font(16px,
                300);
                width: 95%;
            }
            img {
                right: 20px;
                bottom: unset;
                top: -36%;
                display: none;
            }
        }
    }
    .teams {
        padding: 0px 0 0 0;
        .teams-inner {}
        img {
            width: 95%;
        }
    }
    .sectors-inner,
    .services-inner {
        .section-p {
            width: 100%;
        }
    }
    .sectors-list {
        flex-wrap: wrap;
        width: calc(100% + 32px);
        margin: 0 -16px;
        .sector-list-item {
            width: calc(50% - 1px);
            border-radius: 0px;
            margin-top: 1px;
            .sector-name {
                color: $white !important;
                @include font(20px,
                300);
            }
        }
    }
    .services-inner {
        .services-cards {
            .s-card {
                width: 100%;
                padding: 32px 32px 32px;
                box-shadow: 0 -53px 45px rgb(168 168 168 / 17%);
                margin: 16px 0 16px;
                min-height: unset;
                .s-card-head {}
                img {}
            }
            .s-card-header {
                @include font(24px,
                300);
                margin-bottom: 24px;
            }
            .s-card-info {
                @include font(16px,
                300);
                color: $mid-gray;
                line-height: 1.5;
            }
        }
    }
    .experts {
        display: none;
    }
    .experts-heading {
        text-align: center;
        text-transform: uppercase;
        margin: 40px 0 40px;
    }
    .experts-mobile {
        display: block;
        .expertice-line-wrapper-mobile {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            align-items: center;
            .expertise-line {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 16px;
                min-height: 92px;
                width: 30%;
                img {
                    max-width: 100%;
                }
            }
        }
    }
    footer {
        padding: 48px 0;
        background: rgba($color: $light, $alpha: .35);
    }
    .footer-inner {
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }
    .footer-links {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        margin-right: 0px;
        border-right: none;
        flex-wrap: wrap;
        ul {
            width: 100%;
            margin-bottom: 32px;
            &:nth-child(2),
            &:nth-child(3) {
                width: 50%;
            }
        }
    }
    .contact-links {
        width: 100%;
        .lets-talk {
            width: 100%;
            text-align: center;
            justify-content: center;
        }
        ul {
            li {
                text-align: center;
            }
        }
        .social-media {
            justify-content: center;
            li {
                margin: 12px;
            }
        }
    }
    .footer-end-inner {
        padding: 48px 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .copyrights {
            display: flex;
            flex-direction: column;
            flex-grow: 2;
            align-items: center;
            justify-content: center;
            span {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
            }
            img {
                margin: 24px;
            }
        }
        .footer-end-links {
            display: flex;
            justify-content: space-around;
            margin: 32px 0;
            a {
                color: $dark-gray;
                display: flex;
                margin-left: 16px;
                &:hover {
                    color: $primary;
                }
            }
        }
    }
    // footer,
    // .footer-end {
    //     display: none;
    // }
}

@media screen and (max-width: 600px) {
    .scit-tags {
        flex-direction: column;
        span {
            justify-content: flex-start;
            padding-left: 24px;
            font-size: 12px;
        }
    }
}