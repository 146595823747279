@use '../abstract/colors';
//
$gradientRedtoPink: linear-gradient(0deg, colors.$gradient-pink 0%, colors.$gradient-red 100%);
$gradientRedtoPinkAngled: linear-gradient(351deg, #CB7AD0 10%, #ED1C24 100%);
$gradientOrangetoYellow: linear-gradient(0deg, colors.$gradient-orange 0%, colors.$gradient-warm-yellow 100%);
$gradientBluetoPurple: linear-gradient(0deg, colors.$gradient-blue 0%, colors.$gradient-purple 100%);
$gradientGreentoYellow: linear-gradient(0deg, colors.$gradient-green 0%, colors.$gradient-cool-yellow 100%);
//
$gradientNavCards: linear-gradient(90deg, colors.$gradient-nav-card-gray 0%, colors.$gradient-nav-card-mid 20%, colors.$dropmenu-bg 100%);
$gradientAboutCompany: linear-gradient(0deg, #c71c1c 0%, #802424 30%, #1c0000 100%);
// $bannerBreakerBG: linear-gradient(167deg, rgba(216, 59, 59, 0.1) 0%, rgba(137, 98, 118, 0.1) 40%, rgba(59, 128, 167, 0.1) 76%, rgba(128, 177, 206, 0.1) 96%, rgba(147, 186, 206, 0.1) 100%);
$bannerBreakerBG: linear-gradient(153deg, rgba(252, 239, 239, 1) 7%, rgba(240, 245, 248, 1) 37%, rgba(255, 255, 255, 1) 73%);
$bannerBreakerInnerPages: linear-gradient(173deg, rgba(220,61,60,0.2007177871148459) 0%, rgba(220,61,60,0.2007177871148459) 4%, rgba(56,130,170,0.2007177871148459) 37%, rgba(255,255,255,1) 84%, rgba(255,255,255,1) 100%);
$startupPageBannerBG: linear-gradient(171deg, rgba(0, 170, 85, 0.02) 0%, rgba(51, 163, 102, 0.1) 26%, rgba(112, 194, 71, 0.1) 66%, rgba(157, 196, 39, 0.05) 100%);
$allServicesBannerBG: linear-gradient(141deg, rgba(235, 157, 0, 0.05) 0%, rgba(255, 144, 10, 0.098) 27%, rgba(255, 143, 10, 0.1) 27%, rgba(255, 85, 0, 0.02) 100%);
$gradientServicesPagesectionBG: linear-gradient(0deg, colors.$white 0%, #A1C2FF 100%);
$gradientServeicePageHowBG: linear-gradient(129deg, rgba(255, 149, 255, 0.94) 0%, rgba(203, 154, 248, 0.94) 40%, rgba(143, 160, 240, 0.94) 80%, rgba(114, 163, 236, 0.94) 100%);
//
$gradientTeamsBG: linear-gradient(179deg, rgba(234, 234, 191, 0.05) 0%, rgba(255, 165, 135, 0.07) 40%, rgba(255, 73, 73, 0.08) 79%, rgba(234, 21, 32, 0.09) 100%);
//sector-boxes
$gradient-one: linear-gradient(90deg, #FB95FE 0%, #E498FC 100%);
$gradient-two: linear-gradient(90deg, #E198FB 0%, #C99BF8 100%);
$gradient-three: linear-gradient(90deg, #C69BF7 0%, #AE9DF4 100%);
$gradient-four: linear-gradient(90deg, #AB9EF4 0%, #94A0F1 100%);
$gradient-five: linear-gradient(90deg, #91A0F0 0%, #7AA3ED 100%);
$gradient-six: linear-gradient(90deg, #76A3EC 0%, #5FA6EA 100%);
$gradient-seven: linear-gradient(90deg, #5CA6E9 0%, #44A8E6 100%);
$gradient-eight: linear-gradient(90deg, #40A8E5 0%, #29ABE2 100%);
// menu- hover -colours
$menubgs:( o-digital-transformation: #52B490, o-software-development: #A7C2FA, o-mobile-app-development: #EDB242, o-legacy-software:#D84C48, o-data-analytics: #FF8E0F, o-fintech: #3583AA, o-it-consulting: #09B78E, o-dedicated-teams: #6CC6F5, su-mvp-development: #6D3182, su-product-design-proto: #F2C747, su-product-development: #C276BC, su-dedicated-teams: #09B78E, s-ux-development: #09B78E, s-software-development: #E83142, s-iot-app-development: #3583AA, s-mobile-app-development: #EDB242, s-mobile-games: #6D3182, s-qa: #A7C2FA, s-ai: #C276BC, s-dev-ops: #5C5F63, how-we-work: #6CC6F5, our-process: #09B78E, how-we-engage: #FD8E0F, mission-vission: #EA3D41, our-culture: #3583AA, why-sl: #1CB78E, our-method: #E83142, about-us:#FF8E0F);