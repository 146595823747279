.btn{
    border-radius: 8px;
    padding: 16px 24px;
    display: flex;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 32px;
    display: inline-block;
}

.btn-dark{
    background: #323438;
    color: #fff;
}