@use '../abstract/colors' as c;
header {
    display: flex;
    width: 100%;
    //border-bottom: 1px solid rgba($color: #707070, $alpha: 0.25);
    justify-content: center;
    position: fixed;
    z-index: 9999999;
    background-color: c.$white;
    box-shadow: 0 5px 10px rgb(0 0 0 / 8%);
}

.logo {
    max-width: 200px;
    img,
    svg {
        width: 100%;
    }
}