@use '../abstract/' as *;

.wrapper-main .wrapper-inner {
    width: 100%;
}

.hide-this {
    display: none !important;
}

.contact-popup{
    display: none;
}
.contact-popup.show{
    display: block;
}
.contact-popup{
    width: 90%;
    margin: 10px auto;
    padding: 10px 40px;
    border-radius: 10px;
    h2{
        text-align: center;
    }
    p{
        text-align: center;
        padding: 10px 0;
    }
    &#success{
        background-color: rgba($gradient-green,0.1);
        border: 1px solid $gradient-green;
        h2,p{
            color: $gradient-green;
        }
    }
    &#error{
        background-color: rgba($primary,0.1);
        border: 1px solid $primary;
        h2,p{
            color: $primary;
        }
    }
}
label.error.wobble-error{
    color: rgba($primary , 0.6);
    position: absolute;
    bottom: -22px;
    margin-bottom: 0;
    font-size: 13px;
}
.scit-contact .form-field.error{
    border-color: $primary !important;
}