@use '../abstract/' as *;
.nav-bar {
    display: flex;
    width: 90%;
    max-width: $content-width-max;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    .logo {
        img {
            width: 80%;
        }
    }
}

.nav {
    display: flex;
    align-items: center;
    ul.main-nav {
        display: flex;
    }
    .nav-inner {
        display: flex;
        align-items: center;
    }
    .nav-item {
        display: flex;
        position: relative;
        padding: 4px 16px;
        height: 72px;
        align-items: center;
        width: 100%;
        white-space: nowrap;
        &.contact-item {
            padding-right: 0;
            >a {
                display: flex;
                color: $white;
                border-radius: 30px;
                background-color: $primary;
                padding: 4px 16px;
                &:hover {
                    color: $white;
                    background-color: darken($color: $primary, $amount: 10%);
                }
            }
        }
        >a {
            display: flex;
            @include font(12px, 700);
            @include base-transition(color);
            align-items: center;
            &:hover,
            &.active {
                color: $primary;
            }
        }
        &.drop-down-nav-item {
            &:hover {
                .drop-menu {
                    display: flex;
                    &.multi-col-drop-menu {
                        height: calc(100vh - 73px);
                        overflow-y: auto;
                    }
                }
                &:after {
                    content: '';
                    display: flex;
                    position: absolute;
                    // width: 16px;
                    // height: 16px;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                    border-bottom: 8px solid $dropmenu-bg;
                    bottom: -1px;
                    left: 50%;
                    transform: translateX(-50%);
                }
                &:before {
                    content: '';
                    display: flex;
                    position: absolute;
                    // width: 16px;
                    // height: 16px;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                    border-bottom: 8px solid rgba($color: #707070, $alpha: 0.25);
                    bottom: 0px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }
    .drop-icon {
        width: 8px;
        height: 8px;
        margin-left: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.inner-nav-drop-icon {
            width: 12px;
            height: 12px;
        }
        img,
        svg {
            width: 100%;
        }
    }
    .drop-menu-wrapper {
        display: flex;
        flex-wrap: wrap;
        width: 27%;
        margin-right: 24px;
        .drop-menu-list-header {
            width: 100%;
        }
    }
    .drop-menu {
        display: none;
        position: fixed;
        top: 72px;
        width: 100%;
        min-height: calc(100vh - 73px);
        left: 0;
        // transform: translate(-50%, 00%);
        background: $dropmenu-bg;
        border-top: 1px solid rgba($color: #707070, $alpha: 0.25);
        .drop-menu-inner {
            display: flex;
            width: 100%;
            max-width: $content-width-max;
            margin: 0 auto;
            justify-content: flex-start;
            padding: 18px 0 48px 0;
            align-items: flex-start;
        }
        .drop-menu-list {
            width: 100%;
        }
        &.single-drop-menu {
            width: calc((100vw / 5) + 32px);
            min-width: calc(300px);
            position: absolute;
            height: auto;
            border-radius: 20px;
            border: 1px solid #E1E1E1;
            box-sizing: border-box;
            min-height: unset;
            .drop-menu-inner {
                padding: 16px 16px 8px 16px;
                max-width: unset;
                min-width: 300px;
            }
            .drop-menu-list {
                margin: 0;
                width: 100%;
            }
        }
    }
    .drop-menu-list-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h3 {
            display: flex;
            line-height: 1;
            @include font(14px, bold, $dark-gray);
            margin-bottom: 16px;
            margin-top: 16px;
        }
        .drop-icon {
            display: none;
        }
    }
    .drop-menu-items {
        a {
            background: $white;
            background: $gradientNavCards;
            display: flex;
            border-radius: 10px;
            padding: 15px;
            box-shadow: -20px 12px 60px rgba($color: #A8A8A8, $alpha: 0.10);
            margin-bottom: 8px;
            position: relative;
            z-index: 2;
            &::after {
                content: '';
                width: 0%;
                height: 100%;
                position: absolute;
                border-radius: 10px;
                // background: black !important;
                top: 0;
                left: 0;
                z-index: -1;
                transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
            }
            &:hover {
                &::after {
                    width: 100%
                }
            }
        }
        .nav-item-desc {
            h4 {
                @include font(12px, bold, $dark-gray);
                margin-bottom: 12px;
                margin-bottom: 8px;
                margin-top: 3px;
            }
            p {
                @include font(9px, 400, $light-gray);
                white-space: normal;
                line-height: 12px;
                text-transform: initial;
            }
        }
        .nav-item-img {
            display: flex;
            margin-right: 8px;
        }
    }
}

.hamburger {
    cursor: pointer;
    .menu-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 16px;
        height: 16px;
        .menu-stroke {
            width: 16px;
            height: 3px;
            border-radius: 2px;
            background-color: $black;
            position: relative;
            @include base-transition(background-color);
            &::after,
            &::before {
                content: '';
                display: flex;
                position: absolute;
                width: 16px;
                height: 3px;
                border-radius: 2px;
                background-color: $black;
                transform-origin: center;
                @include base-transition(background-color);
            }
            &::after {
                top: -6px;
            }
            &::before {
                bottom: -6px;
            }
        }
        &.menu-close {
            .menu-stroke {
                background-color: transparent;
                &::after {
                    transform: rotate(45deg);
                    top: 0;
                }
                &::before {
                    transform: rotate(-45deg);
                    bottom: 0;
                }
            }
        }
    }
    &:hover {
        .menu-stroke {
            background-color: $primary;
            &::after,
            &::before {
                background-color: $primary;
            }
        }
    }
}

@media screen and (max-width: $content-width-max) {
    .nav {
        .drop-menu {
            .drop-menu-inner {
                width: 90%;
            }
        }
    }
}

@each $name,
$menubg in $menubgs {
    .#{$name}-hbg {
        a {
            &::after {
                background: linear-gradient(90deg, $menubg -90%, rgba(255, 255, 255, 0) 100%) !important;
            }
            &:hover {
                .nav-item-desc {
                    p {
                        color: $dark-gray !important;
                    }
                }
            }
        }
    }
}