// base transitions
$animation-timing: 0.3s;
// Widths & Heights
$content-width-max: 1440px;
$dashWidth: 72px;
$dashWidthMobile: 36px;
$home-card-height: 230px;
//fonts 
// $bannerHeader: 4vw;
//$bannerHeader: clamp(40px, 4vw, 90px);
$bannerHeader: 48px;
$bannerHeaderTab:36px;
// $bannerHeaderMobile: 10vw;
$bannerHeaderMobile: clamp(28px, 10vw, 54px);
// $bannerPara: 1vw;
$bannerPara: 14px;
$bannerParaTab: 14px;
$bannerParaMobile: 14px;
$bannerBreakerHeading: 40px;
$bannerBreakerHeadingTab: 28px;
$bannerBreakerHeadingMobile: clamp(24px, 3vw, 32px);