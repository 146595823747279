@use '../abstract/colors' as c;
* {
    font-family: 'Inter Tight', sans-serif;
    color: c.$black;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
}

a {
    text-decoration: none;
    cursor: pointer;
}

li {
    list-style: none;
}

body {
    overflow-x: hidden;
}

img {
    display: block;
}

a,
h1,
h2,
h3,
h4,
h5,
h6 {
    text-transform: capitalize;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: c.$primary;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}