@use '../abstract/' as *;
@use '../base/' as *;
@use '../components/' as *;
@media screen and (min-width: 1200px) {
    .hamburger {
        display: none;
    }
}

@media screen and (max-width: 1200px) {
    .nav {
        display: flex;
        .nav-inner {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            width: 100%;
            position: absolute;
            width: 100%;
            height: calc(100vh - 92px);
            top: 92px;
            overflow-y: auto;
            left: 0;
            background-color: $dropmenu-bg;
            transform: translate(100%, 0);
            opacity: 0;
            transition: all 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940);
            &.nav-open {
                transform: translate(0, 0);
                opacity: 1;
            }
        }
        ul.main-nav {
            display: flex;
            width: 90%;
            margin: 0 auto;
            flex-wrap: wrap;
        }
        .nav-item {
            display: flex;
            position: relative;
            padding: 4px 16px;
            height: auto;
            align-items: center;
            flex-wrap: wrap;
            white-space: nowrap;
            width: 30%;
            &.contact-item {
                >a {
                    display: flex;
                    color: $white;
                    border-radius: 30px;
                    background-color: $primary;
                    padding: 4px 16px;
                    &:hover {
                        color: $white;
                        background-color: darken($color: $primary, $amount: 10%);
                    }
                }
            }
            >a {
                display: flex;
                @include font(14px, bold);
                @include base-transition(all);
                justify-content: space-between;
                width: 100%;
                padding: 20px 0;
                margin-bottom: 0;
                &:hover {
                    color: $primary;
                }
            }
            &.drop-down-nav-item {
                width: 30%;
                &.about {
                    width: 70%;
                }
                &:hover {
                    .drop-menu {
                        display: flex;
                        height: auto;
                        &.multi-col-drop-menu {
                            height: auto;
                            overflow-y: none;
                        }
                    }
                    &:after {
                        content: '';
                        display: flex;
                        position: absolute;
                        // width: 16px;
                        // height: 16px;
                        border-left: 6px solid transparent;
                        border-right: 6px solid transparent;
                        border-bottom: 8px solid $dropmenu-bg;
                        bottom: -1px;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                    &:before {
                        content: '';
                        display: flex;
                        position: absolute;
                        // width: 16px;
                        // height: 16px;
                        border-left: 6px solid transparent;
                        border-right: 6px solid transparent;
                        border-bottom: 8px solid rgba($color: #707070, $alpha: 0.25);
                        bottom: 0px;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
                &.multi-drop-wrapper {
                    width: 100%;
                }
            }
            &.expertise {
                order: 5;
            }
            &.contact-item {
                order: 9;
            }
            &.why {
                order: 6;
            }
            &.our-clients {
                order: 7;
            }
            &.careers {
                order: 8;
            }
        }
        .drop-icon {
            width: 8px;
            height: 8px;
            margin-left: 8px;
            display: none;
            img,
            svg {
                width: 100%;
            }
        }
        .drop-menu {
            display: flex;
            position: relative;
            top: unset;
            width: 100%;
            min-height: unset;
            left: unset;
            background: $dropmenu-bg;
            border-top: 1px solid rgba($color: #707070, $alpha: 0.25);
            .drop-menu-inner {
                display: flex;
                width: 100%;
                max-width: $content-width-max;
                margin: 0 auto;
                justify-content: flex-start;
                padding: 20px 0;
            }
            .drop-menu-list {
                width: 30%;
                min-width: 200px;
                margin-right: 16px;
            }
            &.single-drop-menu {
                width: 30%;
                position: relative;
                height: auto;
                border-radius: unset;
                border: none;
                box-sizing: border-box;
                min-height: unset;
                .drop-menu-inner {
                    padding: 0;
                    max-width: unset;
                    padding: 20px 0;
                }
                .drop-menu-list {
                    margin: 0;
                }
            }
        }
        .drop-menu-list-header {
            h3 {
                display: flex;
                line-height: 1;
                @include font(14px, 400, #6c6c6c);
                margin-bottom: 16px;
                margin-top: 0;
            }
        }
        .drop-menu-items {
            a {
                background: $white;
                background: $gradientNavCards;
                display: flex;
                border-radius: 10px;
                padding: 4px;
                box-shadow: none;
                margin-bottom: 8px;
            }
            .nav-item-desc {
                h4 {
                    @include font(14px, bold, $dark-gray);
                    margin-bottom: 12px;
                }
                p {
                    @include font(12px, medium, $mid-gray);
                    display: none;
                }
            }
            .nav-item-img {
                display: flex;
                margin-right: 8px;
            }
        }
        .methodes,
        .about {
            >a {
                border-bottom: 1px solid rgba($color: #707070, $alpha: 0.25);
                ;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .nav {
        display: flex;
        .nav-inner {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            width: 100%;
            position: absolute;
            width: 100%;
            min-height: calc(100vh - 72px);
            max-height: calc(100vh - 72px);
            top: 72px;
            left: 0;
            background-color: $dropmenu-bg;
            padding: 24px 0;
            transform: translate(100%, 0) rotateY(-180deg);
            opacity: 0;
            transition: all 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940);
            transform-origin: right;
            height: calc(100vh - 93px) !important;
            overflow-y: auto;
            &.nav-open {
                transform: translate(0, 0) rotateY(0);
                opacity: 1;
            }
        }
        ul.main-nav {
            display: flex;
            width: 100%;
            margin: 0 auto;
            flex-wrap: wrap;
        }
        .nav-item {
            display: flex;
            position: relative;
            padding: 0px 16px;
            height: auto;
            align-items: center;
            flex-wrap: wrap;
            white-space: nowrap;
            width: 100%;
            &.contact-item {
                padding: 12px 16px 0 16px;
                >a {
                    display: flex;
                    color: $white;
                    border-radius: 30px;
                    background-color: $primary;
                    padding: 12px 16px;
                    justify-content: center;
                    align-items: center;
                    &:hover {
                        color: $white;
                        background-color: darken($color: $primary, $amount: 10%);
                    }
                }
            }
            >a {
                display: flex;
                @include font(13px, bold);
                @include base-transition(all);
                justify-content: space-between;
                width: 100%;
                padding: 20px 0;
                margin-bottom: 0;
                &:hover {
                    color: $primary;
                }
            }
            &.drop-down-nav-item {
                width: 100%;
                &.about {
                    width: 100%;
                }
                &.multi-drop-wrapper {
                    width: 100%;
                }
            }
            &.expertise {
                order: 5;
            }
            &.contact-item {
                order: 9;
            }
            &.why {
                order: 6;
            }
            &.our-clients {
                order: 7;
            }
            &.careers {
                order: 8;
            }
        }
        .drop-icon {
            width: 10px;
            height: 10px;
            margin-left: 8px;
            display: flex;
            img,
            svg {
                width: 100%;
            }
        }
        .drop-menu-wrapper {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            margin-right: 0;
            .drop-menu-list-header {
                width: 100%;
                margin: 24px 0;
            }
        }
        .drop-menu {
            display: flex;
            position: relative;
            top: unset;
            width: 100%;
            min-height: unset;
            left: unset;
            background: $dropmenu-bg;
            .drop-menu-inner {
                display: flex;
                width: 100%;
                max-width: $content-width-max;
                margin: 0 auto;
                justify-content: flex-start;
                padding: 20px 0;
                flex-wrap: wrap;
            }
            .drop-menu-list {
                width: 100%;
                min-width: 200px;
            }
            &.single-drop-menu {
                width: 100%;
                position: relative;
                height: auto;
                border-radius: unset;
                border: none;
                box-sizing: border-box;
                min-height: unset;
                min-width: unset;
                .drop-menu-inner {
                    padding: 0;
                    max-width: unset;
                    padding: 20px 0;
                    min-width: unset;
                }
                .drop-menu-list {
                    margin: 0;
                }
            }
        }
        .drop-menu-list-header {
            h3 {
                display: flex;
                line-height: 1;
                @include font(14px, 400, #6c6c6c);
                margin-bottom: 0;
                margin-top: 0;
            }
            .drop-icon {
                display: flex;
            }
        }
        .drop-menu-items {
            a {
                background: $white;
                background: $gradientNavCards;
                display: flex;
                border-radius: 10px;
                padding: 4px;
                box-shadow: none;
                margin-bottom: 8px;
            }
            .nav-item-desc {
                display: flex;
                flex-direction: column;
                justify-content: center;
                h4 {
                    @include font(12px, bold, $dark-gray);
                    margin-bottom: 0;
                }
                p {
                    @include font(12px, medium, $mid-gray);
                    display: none;
                }
            }
            .nav-item-img {
                display: flex;
                margin-right: 8px;
            }
        }
        .services,
        .expertise,
        .why,
        .our-clients,
        .methodes,
        .careers,
        .about {
            >a {
                border-top: 1px solid rgba($color: #707070, $alpha: 0.25);
                border-bottom: none;
            }
        }
        .services {
            a {
                border-top: none;
            }
        }
    }
    @each $name,
    $menubg in $menubgs {
        .#{$name}-hbg {
            a {
                &::after {
                    // background: linear-gradient(90deg, $menubg -90%, rgba(255, 255, 255, 0) 100%) !important;
                    display: none;
                    background: none;
                }
                &:hover {
                    .nav-item-desc {
                        p {
                            color: initial;
                        }
                    }
                }
            }
        }
    }
}