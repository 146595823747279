@use '../abstract/' as *;
.inner-page-banner {
    padding: 72px 0 0;
    background: $bannerBreakerInnerPages;
    &.for-startup-pages {
        background: $startupPageBannerBG;
    }
    &.for-allservices-pages {
        background: url('/images/services-banner-bg-pattern.svg') no-repeat top left, $allServicesBannerBG;
    }
    &.company-info-pages {
        background: url('/images/services-banner-bg-pattern3.svg') no-repeat top right, $gradientAboutCompany;
        background-size: 100%, cover;
        background-blend-mode: multiply;
        min-height: 372px;
        max-height: 400px;
        // &.whyus-hero {
        //     background: url('/images/why-us-banner-bg.svg') no-repeat, url('/images/services-banner-bg-pattern.svg') no-repeat, $allServicesBannerBG;
        // }
        .inner-page-banner-inner {
            min-height: 300px;
            .section-background {
                max-height: 250px;
                img {
                    width: auto;
                    height: auto;
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
        &.career-page {
            .inner-page-banner-inner {
                min-height: unset;
            }
            .section-inner {
                padding-top: 80px;
            }
            .banner-title.light-weight {
                font-weight: 400;
                color: $primary !important;
            }
        }
        &.policy-page {
            .inner-page-banner-inner {
                min-height: unset;
                padding-bottom: 100px;
            }
        }
        .banner-title {
            color: $white !important;
        }
        .contacts-wrapper {
            .banner-title {
                color: initial !important;
                &.white-font {
                    color: $white !important;
                }
            }
        }
    }
    &.why-sl {
        background: url('/images/why-sl-banner-bg.png') no-repeat bottom center, url('/images/company-page-banner-pattern.svg') no-repeat top right, $primary;
        background-size: 100%, 50%;
    }
    .inner-page-banner-inner {
        padding-bottom: 60px;
        display: flex;
        justify-content: space-between;
        .banner-left {
            width: 60%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .section-background {
            width: 40%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            &.ui-ux-bg {}
            img {
                width: auto;
                height: 100%;
                max-height: 40vh;
            }
            &.why-us-banner {
                img {
                    position: absolute;
                    top: -60px;
                }
            }
            &.our-client-banner {
                img {}
            }
        }
    }
    // &.sd-banner {
    //     background: url('/images/software-development-bg.svg') no-repeat right center;
    // }
    // &.dt-banner {
    //     background: url('/images/digital-transform-bg.svg') no-repeat right center;
    // }
    // &.mvp-banner {
    //     background: url('/images/mvp-development-bg.svg') no-repeat right center;
    // }
    // &.pdp-banner {
    //     background: url('/images/product-developmet-bg.svg') no-repeat right center;
    //     background-size: 40%;
    // }
    .inner-page-banner-inner {
        .banner-left {
            width: 50%;
        }
        .section-banner {
            width: 50%;
            img {
                width: 100%;
            }
        }
    }
    .banner-name {
        @include font(18px,
        500);
        margin-bottom: 24px;
    }
    .banner-title {
        @include font($bannerHeader,
        800);
        span {
            color: $primary;
        }
        margin-bottom: 24px;
        text-transform: initial;
    }
    .inner-banner-p {
        @include font($bannerPara,
        500);
        width: 75%;
        color: $mid-gray;
        line-height: 24px;
        margin-bottom: 32px;
        margin-top: 10px;
        &.dedicated-teams-page {
            width: 85%;
        }
        &.white-text {
            color: $white;
        }
    }
    .banner-call-to-action {
        background: $primary;
        color: $white;
        @include font(12px,
        600,
        $white);
        border-radius: 8px;
        padding: 16px 32px;
        border: none;
        position: relative;
        z-index: 2;
        width: fit-content;
        max-width: 150px;
        cursor: pointer;
        &::before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            display: flex;
            background-color: $primary;
            border-radius: 8px;
            top: 0;
            left: 0;
            transform: scale(0.9, 0.7);
            opacity: .3;
            z-index: -2;
            transition: transform 0.3s cubic-bezier(.24, .58, .46, .15);
        }
        &:hover {
            &::before {
                transform: scale(1.1, 1.3);
            }
        }
    }
}

.basic-info {
    background: url('/images/bot-bg-blue.svg') no-repeat bottom right;
    &.our-process-page {
        background: url('/images/ice-burg-top.svg') no-repeat bottom right, $light;
        background-position: 90% bottom;
    }
    &.our-process {
        background: url('/images/ice-burg-bottom.svg') no-repeat top right, $blue;
        background-position: 90% top;
        h3,
        p {
            color: $white !important;
        }
    }
    &.how-we-engage {
        background: url('/images/engage-bg.svg') no-repeat bottom right;
        min-height: 80vh;
    }
    &.dedication-section {
        background: url('/images/dedication-section-bg.svg') no-repeat bottom left;
        // background-position: -30% 100%;
        background-size: auto 430px;
    }
    &.our-culture {
        background: url('/images/culture-section-bg.png') repeat-x bottom center, #D2E4EC;
        // background-position: left 100%;
        background-blend-mode: multiply;
        background-size: auto 160px;

        h5{
            color: #2b5d76;
        }
    }
    img {
        width: 100%;
    }
    &.right-align-section {
        background-position: bottom left;
        .section-title-bar {
            width: 75%;
            justify-content: flex-end;
        }
        .section-title-bar-main {
            .title-icon {
                order: 2;
                margin: 0 0 0 24px;
            }
            .title {
                .pre-heading {
                    right: 0;
                }
                order: 1;
            }
        }
        .basic-info-inner {
            text-align: right;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .section-p {
                text-align: right;
            }
        }
    }
    &.no-bg-section {
        background: none;
    }
}

.dedication-section {
    padding-bottom: 10vh;
}

.our-culture {
    .section-inner {
        padding-bottom: 20vh;
    }
    .blue-title {
        h3 {
            color: $blue !important;
        }
    }
}

.vision-mission {
    padding: 16px;
    border-radius: 10px;
    margin-bottom: 32px;
    &.vision {
        background: url('/images/earth.png') no-repeat, url('/images/vision-bg.png') no-repeat;
        background-size: 12%, cover;
        background-position: 96% 35%, right top;
    }
    &.mission {
        background: url('/images/rocket.png') no-repeat, url('/images/mission-bg.png') no-repeat;
        background-size: 9%, cover;
        background-position: 96% 35%, right top;
    }
}

.basic-info-inner {
    &.full-width p.section-p{
        width: 100%;
    }
}

.section-title-bar {
    display: flex;
    width: 65%;
    margin-bottom: 32px;
    align-items: flex-start;
    flex-wrap: wrap;
    p {
        @include font(14px,
        500,
        $white);
        padding: 16px 0 0;
        width: 80%;
    }
    .section-title-bar-main {
        display: flex;
        align-items: center;
    }
    .title-icon {
        margin-right: 24px;
        min-width: 64px;
        max-width: 64px;
        height: 64px;
        display: flex;
        &.no-icon {
            display: none;
        }
        img {
            width: 100%;
        }
    }
    .title {
        position: relative;
        .pre-heading {
            position: absolute;
            top: -32px;
            @include font(16px,
            400,
            $dark-gray);
        }
        h3 {
            @include font(24px,
            800);
            line-height: 30px;
            text-transform: initial;
        }
        h4 {
            @include font(18px,
            800);
            line-height: 22px;
        }
        p {
            @include font(14px,
            500);
            padding: 16px 0 0;
            width: 100%;
        }
        &.white-text {
            h3,
            p {
                color: $white;
                text-transform: initial;
            }
        }
    }
}

.basic-info-inner {
    padding-bottom: 40px;
    .section-p {
        text-align: left;
        margin: 0 0 24px 0;
        width: 60%;
        font-size: 14px;
        line-height: 24px;
        color: $mid-gray;
        &.secondary-para {
            @include font(14px,
            400,
            $mid-gray);
        }
        &.bold-para {
            font-weight: 700;
        }
        &.full-width-para {
            width: 100%;
        }
    }
}

.inner-page-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 0 40px;
    .card-wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: calc((100% / 3) - 16px);
        border-radius: 10px;
        box-shadow: 0 -53px 45px rgb(168 168 168 / 17%);
        padding: 24px 24px 50px 24px;
        &.full-width {
            width: 100% !important;
        }
    }
    &.three-col {
        .card-wrap {
            width: calc((100% / 3) - 16px);
        }
    }
    &.two-col {
        .card-wrap {
            width: calc((100% / 2) - 16px);
        }
    }
    .card-header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 18px;
        .number {
            width: 60px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            @include font(40px,
            800);
            line-height: 48px;
            background: $orange;
            color: $white;
            border-radius: 8px;
            margin-bottom: 16px;
        }
        .card-heading {
            @include font(24px,
            800);
        }
    }
    .card-p {
        @include font(14px,
        500);
        color: $mid-gray;
        line-height: 26px;
    }
}

.grey-box {
    background-color: rgba($light, 0.66);
    border-radius: 10px;
    margin-bottom: 64px;
    padding: 40px;
    p {
        width: 60%;
        margin: 25px auto 15px auto;
        max-width: 1060px;
        font-size: 16px;
        line-height: 24px;
        color: $mid-gray;
    }
}

.projects {
    .section-inner {
        display: flex;
        justify-content: space-between;
    }
    .project {
        margin-bottom: 48px;
        width: calc((100% /2) - 24px);
        padding: 24px 24px 24px 0;
        &:nth-child(even) {
            padding: 24px 0 24px 24px;
        }
    }
    .section-p {
        width: 100%;
        text-align: left;
        margin: 0 0 24px 0;
    }
    .title {
        margin-bottom: 40px;
        h3 {
            font-size: 28px;
        }
    }
    .img-wrapper {
        margin-bottom: 30px;
        height: 80px;
        display: flex;
        align-items: center;
        img {
            height: 100%;
            width: auto;
            max-width: 240px;
            max-height: 80px;
        }
    }
}

.text-center {
    text-align: center;
}

.process {
    display: flex;
    background: url('/images/bot-bg-blue.svg') no-repeat bottom right, url('/images/dots-upper.svg') no-repeat, $gradientServicesPagesectionBG;
    // height: 50vh;
    //.process-section {
    //background: url('/images/bot-bg-blue.svg') no-repeat bottom right;
    //}
}

.process-section {
    padding-bottom: 40px;
}

.process-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
    .p-card-wrap {
        width: calc((100% / 2) - 8px);
        // background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.7637429971988796) 66%, rgba(255, 255, 255, 0) 100%);
        background: $white;
        padding: 32px;
        border-radius: 10px;
        position: relative;
        display: flex;
        // justify-content: center;
        flex-direction: column;
        margin-bottom: 16px;
        box-shadow: 0 -53px 45px rgb(168 168 168 / 17%);
        min-height: 200px;
        &.quater-width {
            width: calc((100% / 2) - 8px);
        }
        &.half-width {
            width: calc((100% / 2) - 8px);
        }
        &.full-width {
            width: calc((100% / 2) - 8px);
        }
        &:nth-child(1) {
            .p-card-icon {
                background: #3583AA;
            }
        }
        &:nth-child(2) {
            .p-card-icon {
                background: #FF8E0F;
            }
            .p-card-header {
                h4 {
                    &::before {
                        content: '2.';
                    }
                }
            }
        }
        &:nth-child(3) {
            .p-card-icon {
                background: #09B78E;
            }
            .p-card-header {
                h4 {
                    &::before {
                        content: '3.';
                    }
                }
            }
        }
        &:nth-child(4) {
            .p-card-icon {
                background: #6CC6F5;
            }
            .p-card-header {
                h4 {
                    &::before {
                        content: '4.';
                    }
                }
            }
        }
        &:nth-child(5) {
            .p-card-icon {
                background: #EA3D41;
            }
            .p-card-header {
                h4 {
                    &::before {
                        content: '5.';
                    }
                }
            }
        }
        &:nth-child(6) {
            .p-card-icon {
                background: #CD68BF;
            }
            .p-card-header {
                h4 {
                    &::before {
                        content: '6.';
                    }
                }
            }
        }
        &:nth-child(7) {
            .p-card-icon {
                background: #A1C2FF;
            }
            .p-card-header {
                h4 {
                    &::before {
                        content: '7.';
                    }
                }
            }
        }
        &:nth-child(8) {
            .p-card-icon {
                background: #5C5F63;
            }
            .p-card-header {
                h4 {
                    &::before {
                        content: '8.';
                    }
                }
            }
        }
    }
    .card-number {
        display: none;
        // justify-content: center;
        // align-items: center;
        // position: absolute;
        // width: 40px;
        // height: 40px;
        // border-radius: 20px;
        // background: $dark-gray;
        // top: -20px;
        // right: 50%;
        // margin-right: -20px;
        // color: $white;
        // @include font(18px,
        // 800,
        // $white);
    }
    .p-card-header {
        display: flex;
        flex-direction: column;
        align-items: left;
        padding: 0px 0 8px;
        h4 {
            @include font(20px,
            600);
            margin-bottom: 8px;
            text-align: left;
            position: relative;
            &::before {
                content: '1.';
                margin-right: 8px;
                color: $primary;
            }
        }
    }
    .p-card-icon {
        display: none;
        // justify-content: center;
        // align-items: center;
        // width: 120px;
        // height: 120px;
        // background-color: $dark-gray;
        // border-radius: 70px;
        // img {
        //     width: 60px;
        // }
    }
    .p-card-content {
        text-align: left;
        @include font(14px,
        400);
        padding: 0;
        line-height: 24px;
        color: $mid-gray;
    }
}

.white-cards {
    .p-card-icon {
        background-color: transparent !important;
        border-radius: 0;
        img {
            width: auto;
            max-width: 100px;
            max-height: 80px;
        }
    }
    .p-card-content {
        padding: 20px;
        line-height: 26px;
    }
}

.justify-left {
    justify-content: flex-start;
    gap: 16px;
}

.how {
    background: url('/images/bot-bg-blue.svg') no-repeat bottom right, url('/images/dots-upper.svg') no-repeat, $gradientServeicePageHowBG;
}

.how-section {
    padding-bottom: 10px;
}

.how-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .how-card {
        width: calc( 25% - 16px);
        margin-bottom: 64px;
        border-right: 1px solid $white;
        border-image: linear-gradient(to bottom, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }
    .h-card-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 24px;
        .card-header-image {
            min-width: 48px;
            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            background-color: rgba($color: $white, $alpha: .3);
            margin-right: 24px;
            padding: 0 5px;
            img {
                max-width: 36px;
            }
        }
        .h-card-title {
            @include font(20px,
            600,
            $white);
        }
    }
    .h-card-content {
        @include font(16px,
        400,
        $white);
        line-height: 24px;
        width: 90%;
    }
    &.three-col {
        .how-card {
            width: calc( 33.33% - 16px);
        }
    }
}

.question {
    @include font(18px,
    800,
    $black);
    margin-bottom: 8px !important;
}

.answer {
    @include font(16px,
    500,
    $mid-gray);
    margin-bottom: 64px;
    p {
        color: $mid-gray;
        width: 70%;
        margin-bottom: 16px;
    }
    .policy-ul {
        margin: 32px 0 32px 16px;
        li {
            list-style: disc;
            color: $mid-gray;
        }
    }
}

.icon-set {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    .logo-container {
        width: calc((100% / 6) - 16px);
        border-radius: 10px;
        // background: linear-gradient(150deg, rgba(208, 208, 208, 0.25) 0%, rgba(245, 245, 245, 0.3) 67%, rgba(246, 246, 246, 0.29) 71%, rgba(255, 255, 255, 0.17) 100%);
        padding: 16px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 16px;
        margin-bottom: 20px;
        &:last-child {
            margin-right: 0;
        }
        img {
            width: auto;
            height: auto;
            mix-blend-mode: multiply;
            max-width: 100%;
            max-height: 100%;
        }
    }
}

.careers-inner {
    padding-bottom: 60px;
}

.job-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    .job {
        width: 100%;
        margin-bottom: 8px;
        .job-title {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            background: $primary;
            padding: 16px;
            span {
                @include font(20px,
                600,
                $white);
            }
            .arrow-up-down {
                width: 16px;
                height: 16px;
                min-width: 16px;
                min-height: 16px;
                margin-left: 12px;
                background: url('/images/white-arrow-down.png') no-repeat;
                background-size: contain;
                background-position: center center;
                @include base-transition(transform);
            }
        }
        .job-description {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #C5DBE7;
            padding: 64px;
            img {
                width: 100%;
            }
        }
        &:nth-child(n+0) {
            .job-title {
                background: $primary ;
            }
        }
        &:nth-child(2n-1) {
            .job-title {
                background: $dark-gray;
            }
        }
        // &:nth-child(3n+0) {
        //     .job-title {
        //         background: $blue;
        //     }
        // }
        &.open {
            .job-title {
                .arrow-up-down {
                    transform: rotate(-180deg);
                }
            }
        }
    }
}

.contacts-wrapper {
    padding-top: 48px;
    display: flex;
    justify-content: space-between;
    >div {
        width: calc(50% - 48px);
    }
}

.contact-page-heading {
    h1.banner-title {
        font-size: 32px;
        margin-bottom: 16px;
    }
}

.input-wrap {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 36px;
    label {
        display: flex;
        @include font(16px,
        600,
        $dark-gray);
        margin-bottom: 8px;
    }
    input[type=text],
    input[type=email],
    input[type=tel],
    textarea {
        height: 48px;
        border: none;
        border-bottom: 2px solid $dark-gray;
        background: transparent;
        padding: 0 36px;
        @include font(20px,
        400);
        &:focus {
            border-bottom: 3px solid $dark-gray;
            outline: none;
        }
        &::placeholder {
            @include font(20px,
            400,
            #939393);
            opacity: 0.3;
        }
    }
    textarea {
        height: 200px;
        padding: 16px;
    }
    img {
        width: 20px;
        height: 20px;
        position: absolute;
        bottom: 16px;
    }
    input:-internal-autofill-selected {
        background: transparent !important;
    }
    .send {
        border: none;
        background: #323438;
        border-radius: 8px;
        padding: 16px 72px;
        text-align: center;
        @include font(20px,
        600,
        $white);
        width: fit-content;
    }
    &:last-child {
        justify-content: center;
        align-items: center;
    }
}

.contact-info {
    address {
        color: $white;
        font-style: normal;
        margin-bottom: 24px;
        @include font(16px,
        500,
        $mid-gray);
        .contact-highlighted {
            font-weight: 800;
            text-transform: uppercase;
            color: $white;
        }
    }
    img.contact-info-banner {
        margin-top: 64px;
        width: 90%;
    }
}

.inner-page-banner.whyus-hero {
    background-size: contain;
    .why-us-banner {
        width: 40%;
        position: relative;
    }
}
.general-list{
    margin: 20px 0;
    li{
        text-align: left;
        margin: 0 0 24px 0;
        width: 60%;
        font-size: 14px;
        line-height: 24px;
        color: #5C5F63;
    }
}

//case studies
.case-study{
    padding: 50px 0;
}
.tab-wrapper{
    width: 100%;
    display: flex;

    .tab-menu{
        width: 100%;
        max-width: 224px;
        border-right: 1px solid #D3D3D3;
        padding-right: 20px;

        li{
            margin-bottom: 6px;
            a{
                display: flex;
                align-items: center;
                color: #A3A3A3;
                height: 54px;
                border-radius: 6px;
                padding: 0 10px;
                

                    .tab-icon{
                        width:24px;
                        height: 24px;
                        display: block;
                        background-color: #A3A3A3;
                        margin-right: 5px;
                    }
            }

            &.ui-tabs-active{
                a{
                    background-color: #EA3D41;
                    color: #fff;

                    .tab-icon{
                        background-color: #fff;
                    }
                }
            }
        }

    }
    .tab-content{
        width: calc(100% - 224px);
        padding: 0 36px;

        h2{
            font-size: 40px;
            font-weight: 800;
            margin-bottom: 26px;
            color: #323438;
        }
        h3{
           font-size: 18px;
           font-weight: 800; 
           margin-bottom: 26px;
           color: #323438;
           
        }
        h4{
            color: #0084AE;
            font-size: 24px;
            font-weight: 800;
            margin-bottom: 15px;
            margin-top: 10px;
        }
        p{
            font-size: 14px;
            line-height: 24px;
            font-weight: 500;
            color: #5F5F5F;
            margin-bottom: 20px;
        }
        .sub-text{
            font-size: 14px;
            color: #A3A3A3;
            font-size: 500;
        }
        img{
            max-height: 156px;
            width: auto;
            max-width: 100%;
        }

        .blue-box{
            background-color: #3583AA;
            margin: 50px 0;
            padding: 30px 50px;
            border-radius: 6px;
            p{
                font-size: 22px;
                line-height: 32px;
                color: #fff;
                text-align: center;
                margin-bottom: 12px;
            }
        }
    }
}

.icon-list{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;

    .icon-wrapper{
        width: 30%;
        padding: 24px;
        text-align: center;

        img{
            width: auto;
            height: auto;
            max-height: 60px;
            max-width: 88px;
            display: inline-block;
        }
    }
}

.two-col-container{
    margin-top: 50px;
    display: flex;
    .col-box{
      background-color: #fff;
      width: calc(50% - 10px);
      text-align: left;
      padding: 20px 30px;
      border-radius: 10px;
      box-shadow: 0 15px 60px rgba(#A8A8A8 , 0.37);

      .img-wrapper{
        text-align: left;
        margin-bottom: 20px;
      }
      &:first-of-type{
        margin-right: 20px;
      }
    }
}
.icn-heart{
    mask: url(/images/icon_heart.svg) no-repeat center / contain;
    -webkit-mask: url(/images/icon_heart.svg) no-repeat center / contain;
}
.icn-fintech{
    mask: url(/images/icon_fintech.svg) no-repeat center / contain;
    -webkit-mask: url(/images/icon_fintech.svg) no-repeat center / contain;
}

.icn-logistics{
    mask: url(/images/icon_logistics.svg) no-repeat center / contain;
    -webkit-mask: url(/images/icon_logistics.svg) no-repeat center / contain;
}

.icn-edtech{
    mask: url(/images/icon_edtech.svg) no-repeat center / contain;
    -webkit-mask: url(/images/icon_edtech.svg) no-repeat center / contain;
}

.icn-retail{
    mask: url(/images/icon_retail.svg) no-repeat center / contain;
    -webkit-mask: url(/images/icon_retail.svg) no-repeat center / contain;
}
.icn-rnd{
    mask: url(/images/rnd.svg) no-repeat center / contain;
    -webkit-mask: url(/images/rnd.svg) no-repeat center / contain;
}
.icn-leisure{
    mask: url(/images/btc.svg) no-repeat center / contain;
    -webkit-mask: url(/images/btc.svg) no-repeat center / contain;
}
.icn-entertainment{
    mask: url(/images/btb.svg) no-repeat center / contain;
    -webkit-mask: url(/images/btb.svg) no-repeat center / contain;
}

.cs-btn-wrapper{
    padding-top: 50px;
    a{
        min-width: 200px;
    }
}







@media screen and (max-width: 1400px) {
    .inner-page-banner {
        padding: 72px 0 0;
        &.for-startup-pages {
            background: $startupPageBannerBG;
        }
        &.for-allservices-pages {}
        &.company-info-pages {
            background-size: 100%;
            .inner-page-banner-inner {
                min-height: 372px;
                .section-background {
                    img {
                        width: 100%;
                        min-height: auto;
                    }
                }
            }
            &.career-page {
                .inner-page-banner-inner {}
                .section-inner {
                    padding-top: 80px;
                }
                .banner-title.light-weight {}
            }
            &.policy-page {
                .inner-page-banner-inner {
                    padding-bottom: 100px;
                }
            }
            .banner-title {}
            .contacts-wrapper {
                .banner-title {
                    &.white-font {}
                }
            }
        }
        &.why-sl {
            background-size: 100%, 50%;
        }
        .inner-page-banner-inner {
            padding-bottom: 60px;
            display: flex;
            justify-content: space-between;
            .banner-left {
                width: 60%;
            }
            .section-background {
                width: 40%;
                &.ui-ux-bg {}
                img {
                    width: auto;
                    max-height: 35vh;
                }
                &.why-us-banner {
                    img {
                        transform: scale(1);
                        width: auto;
                        transform-origin: top;
                    }
                }
                &.our-client-banner {
                    img {}
                }
            }
        }
        .inner-page-banner-inner {
            .banner-left {
                width: 50%;
            }
            .section-background {
                width: 50%;
                img {
                    width: auto;
                }
            }
        }
        .banner-name {
            @include font(14px,
            500);
            margin-bottom: 24px;
        }
        .banner-title {
            @include font($bannerHeaderTab,
            800);
            span {
                color: $primary;
            }
            margin-bottom: 24px;
            text-transform: initial;
        }
        .inner-banner-p {
            @include font(12px,
            400);
            width: 75%;
            color: $mid-gray;
            line-height: 18px;
            margin-bottom: 32px;
            &.dedicated-teams-page {
                width: 85%;
            }
            &.white-text {
                color: $white;
            }
        }
        .banner-call-to-action {
            background: $primary;
            color: $white;
            @include font(10px,
            600,
            $white);
            border-radius: 6px;
            padding: 12px 24px;
            border: none;
        }
    }
    .basic-info {
        background: url('/images/bot-bg-blue.svg') no-repeat bottom right;
        background-size: 50%;
        &.our-process-page {
            background: url('/images/ice-burg-top.svg') no-repeat bottom right, $light;
            background-position: 90% bottom;
        }
        &.our-process {
            background: url('/images/ice-burg-bottom.svg') no-repeat top right, $blue;
            background-position: 90% top;
            h3,
            p {
                color: $white !important;
            }
        }
        &.how-we-engage {
            background: url('/images/engage-bg.svg') no-repeat bottom right;
            min-height: 80vh;
        }
        &.dedication-section {
            background: url('/images/dedication-section-bg.svg') no-repeat bottom left;
            background-position: -30% 100%;
        }
        &.our-culture {
            background: url('/images/culture-section-bg.png') no-repeat bottom center, #D2E4EC;
            // background-position: left 100%;
            mix-blend-mode: multiply;
        }
        img {
            width: 100%;
        }
        &.right-align-section {
            background-position: bottom left;
            .section-title-bar {
                width: 75%;
                justify-content: flex-end;
            }
            .section-title-bar-main {
                .title-icon {
                    order: 2;
                    margin: 0 0 0 24px;
                }
                .title {
                    .pre-heading {
                        right: 0;
                    }
                    order: 1;
                }
            }
            .basic-info-inner {
                text-align: right;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                .section-p {
                    text-align: right;
                }
            }
        }
        &.no-bg-section {
            background: none;
        }
    }
    .dedication-section {
        padding-bottom: 30vh;
    }
    .our-culture {
        .section-inner {
            padding-bottom: 30vh;
        }
        .blue-title {
            h3 {
                color: $blue !important;
            }
        }

    }
    .vision-mission {
        padding: 16px;
        border-radius: 10px;
        margin-bottom: 32px;
        &.vision {
            background: url('/images/earth.png') no-repeat, url('/images/vision-bg.png') no-repeat;
            background-size: 12%, cover;
            background-position: 96% 35%, right top;
        }
        &.mission {
            background: url('/images/rocket.png') no-repeat, url('/images/mission-bg.png') no-repeat;
            background-size: 9%, cover;
            background-position: 96% 35%, right top;
        }
    }
    .basic-info-inner {}
    .section-title-bar {
        display: flex;
        width: 65%;
        margin-bottom: 24px;
        align-items: flex-start;
        flex-wrap: wrap;
        p {
            @include font(12px,
            500,
            $white);
            padding: 16px 0 0;
            width: 80%;
        }
        .section-title-bar-main {}
        .title-icon {
            margin-right: 12px;
            min-width: 48px;
            max-width: 48px;
            height: 48px;
        }
        .title {
            position: relative;
            .pre-heading {
                position: absolute;
                top: -32px;
                @include font(16px,
                400,
                $dark-gray);
            }
            h3 {
                @include font(20px,
                600);
                line-height: 24px;
                text-transform: initial;
            }
            h4 {
                @include font(14px,
                800);
                line-height: 22px;
            }
            p {
                @include font(12px,
                500);
                padding: 16px 0 0;
                width: 80%;
            }
        }
    }
    .basic-info-inner {
        padding-bottom: 48px;
        .section-p {
            text-align: left;
            margin: 0 0 24px 0;
            width: 60%;
            font-size: 12px;
            line-height: 20px;
            color: $mid-gray;
            &.secondary-para {
                @include font(12px,
                400,
                $mid-gray);
            }
        }
    }
    .inner-page-cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 20px 0 40px;
        .card-wrap {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: calc((100% / 3) - 16px);
            border-radius: 10px;
            box-shadow: 0 -53px 45px rgb(168 168 168 / 17%);
            padding: 16px 16px 50px 16px;
            &.full-width {
                width: 100% !important;
            }
        }
        &.three-col {
            .card-wrap {
                width: calc((100% / 3) - 16px);
            }
        }
        &.two-col {
            .card-wrap {
                width: calc((100% / 2) - 16px);
            }
        }
        .card-header {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 18px;
            .number {
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                @include font(28px,
                800);
                line-height: 40px;
                background: $orange;
                color: $white;
                border-radius: 8px;
                margin-bottom: 12px;
            }
            .card-heading {
                @include font(16px,
                800);
            }
        }
        .card-p {
            @include font(12px,
            500);
            color: $mid-gray;
            line-height: 20px;
        }
    }
    .grey-box {
        background-color: rgba($light, 0.66);
        border-radius: 10px;
        margin-bottom: 60px;
        padding: 24px;
        p {
            width: 60%;
            margin: 25px auto 15px auto;
            max-width: 1060px;
            font-size: 14px;
            line-height: 20px;
            color: $mid-gray;
        }
    }
    .projects {
        .project {
            margin-bottom: 40px;
        }
        .section-p {
            width: 100%;
            text-align: left;
        }
        .title {
            margin-bottom: 28px;
            h3 {
                font-size: 20px;
            }
        }
        .img-wrapper {
            margin-bottom: 30px;
            height: 40px;
            img {
                height: 100%;
                width: auto;
            }
        }
    }
    .text-center {
        text-align: center;
    }
    .process {
        display: flex;
        background: url('/images/bot-bg-blue.svg') no-repeat bottom right, url('/images/dots-upper.svg') no-repeat, $gradientServicesPagesectionBG;
        background-size: 50%, 50%, cover;
        background-blend-mode: soft-light;
        // height: 50vh;
        //.process-section {
        //background: url('/images/bot-bg-blue.svg') no-repeat bottom right;
        //}
    }
    .process-section {
        padding-bottom: 40px;
    }
    .process-cards {
        .p-card-wrap {
            width: calc((100% / 2) - 8px);
            padding: 32px;
            border-radius: 8px;
            min-height: 170px;
        }
        .card-number {
            display: none;
            // justify-content: center;
            // align-items: center;
            // position: absolute;
            // width: 40px;
            // height: 40px;
            // border-radius: 20px;
            // background: $dark-gray;
            // top: -20px;
            // right: 50%;
            // margin-right: -20px;
            // color: $white;
            // @include font(18px,
            // 800,
            // $white);
        }
        .p-card-header {
            padding: 0px 0 8px;
            h4 {
                @include font(16px,
                600);
                margin-bottom: 8px;
            }
        }
        .p-card-content {
            @include font(12px,
            400);
            line-height: 20px;
            color: $mid-gray;
        }
    }
    .white-cards {
        .p-card-icon {
            background-color: transparent !important;
            border-radius: 0;
            img {
                width: auto;
                max-width: 100px;
                max-height: 80px;
            }
        }
        .p-card-content {
            padding: 20px;
            line-height: 26px;
        }
    }
    .how {
        background: url('/images/bot-bg-blue.svg') no-repeat bottom right, url('/images/dots-upper.svg') no-repeat, $gradientServeicePageHowBG;
        background-size: 50%, 50%, cover;
        background-blend-mode: soft-light;
    }
    .how-section {
        padding-bottom: 20px;
    }
    .how-cards {
        .how-card {
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }
        .h-card-header {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 16px;
            .card-header-image {
                min-width: 40px;
                height: 40px;
                border-radius: 8px;
                margin-right: 10px;
                padding: 0 5px;
                img {
                    max-width: 24px;
                }
            }
            .h-card-title {
                @include font(16px,
                600,
                $white);
            }
        }
        .h-card-content {
            @include font(12px,
            400,
            $white);
            line-height: 20px;
            width: 90%;
        }
        &.three-col {
            .how-card {
                width: calc( 33.33% - 16px);
            }
        }
    }
    .question {
        @include font(18px,
        800,
        $black);
        margin-bottom: 8px;
    }
    .answer {
        @include font(16px,
        500,
        $mid-gray);
        margin-bottom: 64px;
        p {
            color: $mid-gray;
            width: 70%;
            margin-bottom: 16px;
        }
        .policy-ul {
            margin: 32px 0 32px 16px;
            li {
                list-style: disc;
                color: $mid-gray;
            }
        }
    }
    .icon-set {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        .logo-container {
            width: calc((100% / 8) - 8px);
            border-radius: 10px;
            // background: linear-gradient(150deg, rgba(208, 208, 208, 0.25) 0%, rgba(245, 245, 245, 0.3) 67%, rgba(246, 246, 246, 0.29) 71%, rgba(255, 255, 255, 0.17) 100%);
            padding: 16px;
            height: 90px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 8px;
            margin-bottom: 20px;
            &:last-child {
                margin-right: 0;
            }
            img {
                width: auto;
                height: auto;
                mix-blend-mode: multiply;
            }
        }
    }
    .careers-inner {
        padding-bottom: 120px;
    }
    .job-list {
        display: flex;
        flex-direction: column;
        width: 100%;
        .job {
            width: 100%;
            margin-bottom: 8px;
            .job-title {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: space-between;
                background: $primary;
                padding: 16px;
                span {
                    @include font(20px,
                    600,
                    $white);
                }
                .arrow-up-down {
                    width: 16px;
                    height: 16px;
                    min-width: 16px;
                    min-height: 16px;
                    margin-left: 12px;
                    background: url('/images/white-arrow-down.png') no-repeat;
                    background-size: contain;
                    background-position: center center;
                    @include base-transition(transform);
                }
            }
            .job-description {
                display: flex;
                align-items: center;
                justify-content: center;
                background: #C5DBE7;
                padding: 64px;
                img {
                    width: 100%;
                }
            }
            &:nth-child(n+0) {
                .job-title {
                    background: $primary;
                }
            }
            &:nth-child(3n-1) {
                .job-title {
                    background: $dark-gray;
                }
            }
            &:nth-child(3n+0) {
                .job-title {
                    background: $blue;
                }
            }
            &.open {
                .job-title {
                    .arrow-up-down {
                        transform: rotate(-180deg);
                    }
                }
            }
        }
    }
    .contacts-wrapper {
        >div {
            width: calc(50% - 48px);
        }
    }
    .contact-page-heading {
        h1.banner-title {
            font-size: 28px;
        }
    }
    .input-wrap {
        margin-bottom: 24px;
        label {
            display: flex;
            @include font(12px,
            600,
            $dark-gray);
        }
        input[type=text],
        input[type=email],
        textarea {
            height: 48px;
            padding: 0 36px;
            @include font(14px,
            400);
            &:focus {}
            &::placeholder {
                @include font(12px,
                400,
                #939393);
            }
        }
        textarea {
            height: 140px;
            padding: 16px;
            color: $dark-gray;
            font-size: 12px;
        }
        img {
            width: 16px;
            height: 16px;
            position: absolute;
            bottom: 16px;
        }
        .send {
            padding: 12px 48px;
            @include font(12px,
            600,
            $white);
        }
        &:last-child {}
    }
    .contact-info {
        address {
            margin-bottom: 18px;
            @include font(16px,
            500,
            $mid-gray);
        }
        img.contact-info-banner {}
    }
    .inner-page-banner.whyus-hero {
        .why-us-banner {}
    }
}

.input-wrap img {
    width: auto;
}

@media screen and (max-width: 1024px) {
    .inner-page-banner {
        padding: 92px 0 0;
        background: $bannerBreakerBG;
        .inner-page-banner-inner {
            padding-bottom: 60px;
            background-size: 50%;
            background-position: 138% 50%;
            display: flex;
            min-height: auto;
            .banner-left {
                width: 60%;
            }
            .section-background {
                width: 40%;
                position: relative;
                img {
                    width: 100%;
                    position: absolute;
                    bottom: -20%;
                    right: -10%;
                }
            }
            .banner-name {
                @include font(32px,
                500);
                margin-bottom: 32px;
            }
            .banner-title {
                @include font(40px,
                800);
                span {
                    color: $primary;
                }
                margin-bottom: 24px;
            }
            .inner-banner-p {
                @include font(16px,
                500);
                width: 90%;
                margin-bottom: 32px;
            }
        }
        &.company-info-pages {
            .inner-page-banner-inner {
                .inner-banner-p {
                    color: $white;
                }
            }
        }
    }
    .section-title-bar {
        display: flex;
        width: 100%;
        margin-bottom: 24px;
        align-items: flex-start;
        flex-wrap: wrap;
        .section-title-bar-main {
            display: flex;
            align-items: flex-start;
            flex-wrap: nowrap;
        }
        .title-icon {
            margin-right: 24px;
            min-width: 54px;
            height: 54px;
            display: flex;
            img {
                width: 100%;
            }
        }
        .title {
            h3 {
                @include font(24px,
                800);
                line-height: 56px;
                line-height: 28px;
            }
            p {
                @include font(16px,
                500);
                padding: 32px 0;
                width: 100%;
            }
            &.white-text {
                h3,
                p {
                    color: $white;
                }
            }
        }
    }
    .basic-info {
        background: url('/images/bot-bg-blue.svg') no-repeat bottom right;
        background-size: 100%;
        &.our-process-page {
            background: none;
        }
        &.no-bg-section {
            background: none;
        }
    }
    .basic-info-inner {
        padding-bottom: 80px;
        .section-p {
            text-align: left;
            margin: 0 0 24px 0;
            width: 100%;
            padding: 0;
        }
    }
    .inner-page-cards {
        .card-wrap {
            width: calc(100%);
            margin-bottom: 16px;
        }
        &.three-col {
            .card-wrap {
                width: calc(100%);
            }
        }
        &.two-col {
            .card-wrap {
                width: calc(100%);
            }
        }
        .card-header {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 18px;
            .number {
                width: 80px;
                height: 80px;
                display: flex;
                justify-content: center;
                align-items: center;
                @include font(60px,
                800);
                line-height: 60px;
                background: $orange;
                color: $white;
                border-radius: 8px;
                margin-bottom: 16px;
            }
            .card-heading {
                @include font(24px,
                800);
            }
        }
        .card-p {
            @include font(16px,
            500);
        }
    }
    .process-cards {
        .p-card-wrap {
            width: calc((100% / 2) - 16px);
            &.quater-width {
                width: calc((100% / 2) - 16px);
            }
        }
        &.odd-cards {
            .p-card-wrap {
                &:last-child {
                    width: calc(100%);
                }
            }
        }
    }
    .how {
        background: url('/images/bot-bg-blue.svg') no-repeat bottom right, url('/images/dots-upper.svg') no-repeat, $gradientServeicePageHowBG;
        background-size: 100%;
    }
    .how-cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .how-card {
            width: calc(50% - 16px);
            margin: 0 0 48px 0;
        }
    }
    .icon-set {
        .logo-container {
            width: calc((100% / 3) - 16px);
            padding: 12px;
            margin-right: 12px;
            &:last-child {}
            img {
                max-width: 100%;
            }
        }
    }
    .contacts-wrapper {
        flex-wrap: wrap;
        >div {
            width: calc(100%);
        }
    }
    .projects {
        .section-inner {
            display: flex;
            justify-content: space-between;
        }
        .project {
            margin-bottom: 40px;
            width: calc((100% /2) - 12px);
            padding: 12px 12px 12px 0;
            &:nth-child(even) {
                padding: 12px 0 12px 12px;
            }
        }
        .section-p {
            width: 100%;
            text-align: left;
            padding: 0;
        }
        .title {
            margin-bottom: 28px;
            h3 {
                font-size: 16px;
            }
        }
    }
    .two-col-container{
        flex-direction: column;
        .col-box{
            width: 100%;
            margin-bottom: 50px;
        }
    }
}

@media screen and (max-width: 767px) {
    .inner-page-banner {
        padding: 72px 0 0;
        background: $bannerBreakerBG;
        &.company-info-pages {
            .inner-page-banner-inner {
                min-height: auto;
                .inner-banner-p {
                    color: $white;
                }
            }
        }
        .inner-page-banner-inner {
            padding-bottom: 0px;
            background: none !important;
            display: flex;
            min-height: auto;
            .banner-left {
                width: 100%;
            }
            .section-background {
                width: 40%;
                position: relative;
                display: none;
                img {
                    width: 100%;
                    position: absolute;
                    bottom: -20%;
                    right: -10%;
                }
            }
            .banner-name {
                @include font(20px,
                500);
                margin-bottom: 12px;
            }
            .banner-title {
                @include font(32px,
                800);
                span {
                    color: $primary;
                }
                margin-bottom: 24px;
            }
            .inner-banner-p {
                @include font(16px,
                500);
                width: 90%;
                margin-bottom: 32px;
            }
        }
    }
    .section-title-bar {
        display: flex;
        width: 100%;
        margin-bottom: 24px;
        align-items: flex-start;
        flex-wrap: wrap;
        .section-title-bar-main {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
        }
        .title-icon {
            margin-right: 24px;
            min-width: 54px;
            height: 54px;
            display: none;
            img {
                width: 100%;
            }
        }
        .title {
            h3 {
                @include font(32px,
                800);
                line-height: 56px;
                line-height: 38px;
            }
            p {
                @include font(22px,
                500);
                padding: 32px 0;
                width: 100%;
            }
            &.white-text {
                h3,
                p {
                    color: $white;
                }
            }
        }
    }
    .basic-info {
        background: url('/images/bot-bg-blue.svg') no-repeat bottom right;
        background-size: 100%;
        &.dedication-section {
            background: url('/images/dedication-section-bg.svg') no-repeat bottom left;
            background-position: 50% 100%;
            background-size: 130%;
        }
    }
    .basic-info-inner {
        padding-bottom: 80px;
        .section-p {
            text-align: left;
            margin: 0 0 24px 0;
            width: 100%;
            padding: 0;
            @include font(16px,
            400);
            line-height: 24px;
        }
    }
    .inner-page-cards {
        .card-wrap {
            width: calc(100%);
            margin-bottom: 16px;
        }
        &.three-col {
            width: calc(100%);
        }
        &.two-col {
            width: calc(100%);
        }
        .card-header {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 18px;
            .number {
                width: 80px;
                height: 80px;
                display: flex;
                justify-content: center;
                align-items: center;
                @include font(60px,
                800);
                line-height: 60px;
                background: $orange;
                color: $white;
                border-radius: 8px;
                margin-bottom: 16px;
            }
            .card-heading {
                @include font(24px,
                800);
            }
        }
        .card-p {
            @include font(16px,
            500);
        }
    }
    .process-cards {
        .p-card-wrap {
            width: calc(100%);
            &.quater-width {
                width: calc(100%);
            }
            &.half-width {
                width: calc(100%);
            }
            &.full-width {
                width: calc(100%);
            }
        }
    }
    .how {
        background: url('/images/bot-bg-blue.svg') no-repeat bottom right, url('/images/dots-upper.svg') no-repeat, $gradientServeicePageHowBG;
        background-size: 100%;
    }
    .how-cards {
        display: flex;
        flex-wrap: wrap;
        .how-card {
            width: calc(100%);
            margin: 0 0 48px 0;
        }
    }
    .icon-set {
        .logo-container {
            width: calc((100% / 2) - 12px);
            padding: 16px;
            margin-right: 12px;
            &:last-child {}
            img {
                max-width: 100%;
            }
        }
    }
    .job-list {
        .job {
            .job-title {}
            .job-description {
                padding: 16px;
                img {
                    width: 100%;
                }
            }
        }
    }
    section#howwework:before,
    section#ourprocess:before,
    section#howweengage:before {
        height: 50px;
        content: '';
        display: block;
    }
    .projects {
        .section-inner {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
        .project {
            margin-bottom: 20px;
            width: calc((100%));
            padding: 12px 12px 12px 0;
            &:nth-child(even) {
                padding: 12px 12px 12px 0;
            }
        }
        .section-p {
            width: 100%;
            text-align: left;
            padding: 0;
        }
        .title {
            margin-bottom: 28px;
            h3 {
                font-size: 16px;
            }
        }
    }
    /* Hide tabs on mobile */
    #tabs ul {
        display: none;
    }
    .case-study{
        select{
            display: block;
            margin: 0 auto;
            height: 50px;
            width: 100%;
            text-align: center;
            background-color: #000;
            color: #fff;
            font-size: 16px;
        }
    }
    .tab-wrapper {
        flex-direction: column;
        .tab-menu{
            border-right: none;
            margin-bottom: 48px;
            max-width: initial;
        }
        .tab-content{
            width: 100%;
        }
    }

}

.section-inner {
    padding-top: 36px;
    &.full-width-para .section-p {
        width: 100%;
    }
}

// .our-culture,
// .dedication-section{
//     &::before{
//         content: '';
//         display: block;
//         height: 92px;
//         margin-top: -92px;
//         background: $white;
//         mix-blend-mode: multiply;
//     }
// }