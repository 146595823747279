@use '../abstract/varriables' as *;
@use '../abstract/colors' as *;
@mixin gradient($direction, $color1, $color2) {
    background: $color1;
    background: linear-gradient($direction, $color1, $color2);
}

$weight: ("light": 300, "regular": 400, "medium": 500, "bold": 600, "semibold": 700, "black": 800);
@mixin font($size, $weight, $color: $dark-gray) {
    font-size: $size;
    font-weight: $weight;
    color: $color;
}

@mixin base-transition($element) {
    transition: $element $animation-timing ease-in-out;
}