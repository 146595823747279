// Basic Colors 
$primary: #EA3D41;
$primary2: #612A2A;
$orange: #FF8E0F;
$blue: #3583AA;
$light: #EAEAEA;
$dropmenu-bg: #F7F7F7;
$scit-tags: #DE453B;
// Font Colors
$dark-gray: #323438;
$mid-gray: #5C5F63;
$light-gray: #9D9D9D;
// Nutral Colors
$black: #000;
$white: #fff;
// Gradients-colors
$gradient-red: #ED1C24;
$gradient-pink: #CB7AD0;
$gradient-orange: #F15024;
$gradient-warm-yellow: #FBDB0C;
$gradient-blue: #29ABE2;
$gradient-purple: #FF95FF;
$gradient-green: #229F73;
$gradient-cool-yellow: #D9E021;
$gradient-nav-card-gray: rgba(252, 252, 252, 1);
$gradient-nav-card-mid: rgba(247, 247, 247, 1);
$gradient-transparent: rgba(255, 255, 255, 0);